
import VueDatepickerLocal from 'vue-datepicker-local'
export default {
	name: 'projectTabel',
	data() {
		return {
			sex: 'girl',
			list: '',
			total: 0,
			pageSize: 0,
			currentPage: 1,
			restaurants: [],
			state1: '',
			options: [{
				label: '全部',
				value: ''
			}, {
				label: '初始状态',
				value: 0
			}, {
				label: '待启动',
				value: 1
			}, {
				label: '进行中',
				value: 2
			}, {
				label: '中心关闭',
				value: 3
			},],
			state: '',
			department: 0,
			departmentList: [],
		}
	},
	created() {
		this.token = this.getCookie('token')
		this.getTableList()
		this.getDepartmentList()
	},

	filters: {
		stateFilter(data) {
			if (data == 1) {
				data = '待启动';
			} else if (data == 2) {
				data = '进行中';
			} else if (data == 3) {
				data = '中心关闭';
			} else {
				data = '初始状态'
			}
			return data;
		}
	},
	components: {
		VueDatepickerLocal
	},
	methods: {
		getTableList() {
			var _this = this;
			_this.$http.post(_this.craUrl + '/reportStatisticsFrequency', {
				departmentId: _this.department ? _this.department : '',
				page: _this.currentPage,
				state: _this.state,
				projectName: _this.state1,
			}, {
				headers: {
					"token": _this.token
				}
			}).then(function (response) {
				if (response.data.code == 200) {
					_this.list = response.data.data.objArray;
				} else if (response.data.code == 401) {
					_this.$router.push('/')
				}
			})
		},
		//科室下拉框
		getDepartmentList() {
			var _this = this;
			_this.$http.post(_this.craUrl + '/findDepartmentList', {}, {
				headers: {
					"token": _this.token
				}
			}).then(function (response) {
				if (response.data.code == 200) {
					_this.departmentList = response.data.data.array;
					_this.departmentList.unshift({ 'id': 0, 'name': '全部' })
					_this.department = _this.departmentList[_this.$route.params.id].name

				}
			})
		},
		//搜索框
		querySearch(queryString, cb) {
			var restaurants = this.restaurants;
			var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
			// 调用 callback 返回建议列表的数据
			cb(results);
		},
		createFilter(queryString) {
			return (restaurant) => {
				return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
			};
		},
		//点击分页
		currentPageChanged(currentPage) {
			this.currentPage = currentPage
			this.getTableList()
		},
		//点击跳转详情
		inspectParticulars(id) {
			this.$router.push({
				name: 'inspectParticulars',
				params: {
					id: id
				}
			})
			//				console.log(id)
		},
		//模板下载
		templateDownLoad() {
			var _this = this;
			_this.$http.get('/API/PLAT/templateProjectDown', {
				responseType: 'blob'
			}, {}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					//					console.log(response)
					let data = new Blob([response.data], {
						type: 'application/vnd.ms-excel'
					})
					let objectUrl = URL.createObjectURL(data);
					window.location.href = objectUrl;
				})
		},
	},
	//		beforeRouteLeave(to, from, next) {
	//	         // 设置下一个路由的 meta
	//	        from.meta.keepAlive = true;  // B 跳转到 A 时，让 A 缓存，即不刷新
	//	        next();
	//	    }
}
